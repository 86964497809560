* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #31333a;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", 
    monospace;
}
.styles_links__2WYVg {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
}
.styles_action__2jJ3i {
  position: relative;
  border-radius: 0px; /* Adjust the border-radius as needed for slightly rounded corners */
  border: 1px solid #fff;
  transition: all 300ms ease-out;
  width: 85px; /* Set the desired width for the rectangular box */
  height: 55px; /* Set the desired height for the rectangular box */
  margin: 0em 0.25em 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}


.styles_action__2jJ3i:last-child {
  margin-right: 0;
}

.styles_actionBg__4eqJp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.styles_actionBg__4eqJp svg {
  width: 50%;
  height: 50%;
  fill: #fff;
}

.styles_actionBgPane__1MXUt {
  transition: all 300ms ease-out;
  border-radius: 50%;
  width: 0;
  height: 0;
}

.styles_action__2jJ3i:hover {
  transition: all 100ms ease-out;
}

.styles_action__2jJ3i:hover .styles_actionBgPane__1MXUt {
  width: 100%;
  height: 100%;
  transition: all 100ms ease-out;
}

.styles_facebook__bWKTw .styles_actionBgPane__1MXUt { background: #3b5998; }
.styles_facebook__bWKTw:hover { border-color: #3b5998; }

.styles_twitter__3zi5E .styles_actionBgPane__1MXUt { background: #2795e9; }
.styles_twitter__3zi5E:hover { border-color: #2795e9; }

.styles_linkedin__1b0dh .styles_actionBgPane__1MXUt { background: #0077b5; }
.styles_linkedin__1b0dh:hover { border-color: #0077b5; }

.styles_github__35Dox .styles_actionBgPane__1MXUt { background: #c9510c; }
.styles_github__35Dox:hover { border-color: #c9510c; }

.styles_medium__1EJ9p .styles_actionBgPane__1MXUt { background: #00ab6c; }
.styles_medium__1EJ9p:hover { border-color: #00ab6c; }

.styles_email__1oEmN .styles_actionBgPane__1MXUt { background: #777; }
.styles_email__1oEmN:hover { border-color: #777; }

@media only screen and (max-width: 768px) {
  .styles_links__2WYVg {
    position: relative;
    left: 0.5em;
    flex-wrap: wrap;
  }

  .styles_action__2jJ3i:last-child {
    margin-right: 1em;
  }
}

.styles_container__2ICYQ {
  height: 100vh;
}

.styles_overlay__3PX73 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  pointer-events: none;
}

.styles_title__3mB7N {
  font-size: 4.25em;
  font-weight: 425;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  margin: 0.25em;
  color: #fff;
}

.styles_subtitle__OL7sW {
  font-size: 2.5em;
  font-weight: 400;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  margin: 0 0 0.5em;
  height: 1em;
  line-height: 1em;
  color: #fff;
}

.styles_links__1UzBY {
  position: absolute;
  display: inline-block;
  right: 1.5em;
  bottom: 1.5em;
}

@media only screen and (max-width: 768px) {
  .styles_title__3mB7N {
    font-size: 3em;
  }

  .styles_subtitle__OL7sW {
    font-size: 1.5em;
  }

  .styles_links__1UzBY {
    padding: 0 1em;
    display: block;
    width: 100%;
    right: initial;
  }
}

