.container {
  height: 100vh;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  pointer-events: none;
}

.title {
  font-size: 4.25em;
  font-weight: 425;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  margin: 0.25em;
  color: #fff;
}

.subtitle {
  font-size: 2.5em;
  font-weight: 400;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
  margin: 0 0 0.5em;
  height: 1em;
  line-height: 1em;
  color: #fff;
}

.links {
  position: absolute;
  display: inline-block;
  right: 1.5em;
  bottom: 1.5em;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 3em;
  }

  .subtitle {
    font-size: 1.5em;
  }

  .links {
    padding: 0 1em;
    display: block;
    width: 100%;
    right: initial;
  }
}
